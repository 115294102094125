import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Input, Select } from '../AmelieUI';
import Chart from 'chart.js/auto';
import Utils from '../../utils/utils';
import './AmelieMortgageCalculator.scss';

const CHART_COLORS = {
  principal: '#64748b',
  interest: '#94a3b8',
};

const API_ENDPOINTS = {
  CA: 'https://www.bankofcanada.ca/valet/observations/V80691311/json?recent=1',
  US: 'https://api.fiscaldata.treasury.gov/services/api/fiscal_service/v2/accounting/od/avg_interest_rates?sort=-record_date&limit=1',
};

const COMPOUNDING = {
  CA: 2,  // semi-annual compounding
  US: 12, // monthly compounding
};

const AmelieMortgageCalculator = () => {
  const [price, setPrice] = useState('');
  const [downPayment, setDownPayment] = useState('10');
  const [isPercentage, setIsPercentage] = useState(true);
  const [interestRate, setInterestRate] = useState('5.99');
  const [amortization, setAmortization] = useState('25');
  const [paymentFrequency, setPaymentFrequency] = useState('12');
  const [payment, setPayment] = useState(null);
  const [chartData, setChartData] = useState(null);
  
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  // Get locale and default price from config
  const locale = Utils.getDefaultConfig('locale') || 'en-us';
  const defaultPrice = Utils.getDefaultConfig('price');
  const country = locale === 'en-ca' ? 'CA' : 'US';

  useEffect(() => {
    if (defaultPrice) {
      setPrice(defaultPrice.toString());
    }
    fetchCurrentRate();
  }, []);

  const fetchCurrentRate = async () => {
    try {
      const response = await fetch(API_ENDPOINTS[country]);
      const data = await response.json();
      
      let rate;
      if (country === 'CA') {
        const primeRate = parseFloat(data.observations?.[0]?.V80691311?.v);
        rate = primeRate ? (primeRate * 0.96).toFixed(2) : null;
      } else {
        const treasuryRate = parseFloat(data.data?.[0]?.avg_interest_rate_amt);
        rate = treasuryRate ? (treasuryRate * 1.44).toFixed(2) : null;
      }

      if (rate) {
        setInterestRate(rate);
      }
    } catch (error) {
      console.error('Failed to fetch current rate:', error);
    }
  };

  const calculatePayment = (loanAmount, rate, paymentsPerYear, totalPayments) => {
    const nominalRate = rate / 100;
    const compoundingFrequency = COMPOUNDING[country];
    
    // Calculate effective annual rate based on country's compounding frequency
    const effectiveAnnualRate = Math.pow(1 + nominalRate / compoundingFrequency, compoundingFrequency) - 1;
    
    // Calculate periodic rate based on payment frequency
    const periodicRate = Math.pow(1 + effectiveAnnualRate, 1 / paymentsPerYear) - 1;
    
    // Calculate payment using the periodic rate
    return (periodicRate * loanAmount) / 
           (1 - Math.pow(1 + periodicRate, -totalPayments));
  };

  useEffect(() => {
    if (chartRef.current && chartData) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      chartInstance.current = new Chart(chartRef.current, {
        type: 'doughnut',
        data: {
          labels: ['Principal amount', 'Interest amount'],
          datasets: [{
            data: [chartData.principal, chartData.interest],
            backgroundColor: [CHART_COLORS.principal, CHART_COLORS.interest],
          }],
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                boxWidth: 12,
                padding: 8,
                font: { size: 11 },
              },
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const value = context.raw;
                  return `${context.label}: ${formatCurrency(value)}`;
                },
              },
            },
          },
        },
      });
    }
  }, [chartData]);

  const toggleDownPaymentType = () => {
    const currentValue = parseFloat(downPayment) || 0;
    if (isPercentage && price) {
      // Convert percentage to amount
      const amount = Math.round((currentValue / 100) * parseFloat(price));
      setDownPayment(amount.toString());
    } else if (!isPercentage && price) {
      // Convert amount to percentage
      const percentage = ((currentValue / parseFloat(price)) * 100).toFixed(2);
      setDownPayment(percentage);
    }
    setIsPercentage(!isPercentage);
  };

  const calculateMortgage = useCallback(() => {
    if (!price) return;

    const purchasePrice = parseFloat(price);
    const downPaymentValue = isPercentage ? 
      Math.round(purchasePrice * (parseFloat(downPayment) / 100)) : 
      parseFloat(downPayment);
    
    const loanAmount = purchasePrice - downPaymentValue;
    const paymentsPerYear = parseInt(paymentFrequency);
    const totalPayments = parseInt(amortization) * paymentsPerYear;
    
    const periodicPayment = calculatePayment(
      loanAmount,
      parseFloat(interestRate),
      paymentsPerYear,
      totalPayments
    );
    
    const totalPaid = periodicPayment * totalPayments;
    const totalInterest = Math.max(0, totalPaid - loanAmount);
    
    setPayment(periodicPayment);
    setChartData({
      principal: loanAmount,
      interest: totalInterest,
    });
  }, [price, downPayment, isPercentage, paymentFrequency, amortization, interestRate]);

  // Run calculation on any input change
  useEffect(() => {
    const timer = setTimeout(() => {
      calculateMortgage();
    }, 300);
    return () => clearTimeout(timer);
  }, [calculateMortgage]);

  const formatCurrency = (value) => {
    if (!value) return '';
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: locale === 'en-ca' ? 'CAD' : 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formatter.format(value);
  };

  const getPaymentLabel = () => {
    switch (paymentFrequency) {
      case '26': return 'bi-weekly payment';
      case '52': return 'weekly payment';
      default: return 'monthly payment';
    }
  };

  return (
    <div className="amelie-mortgage-calculator">
      <div className="amelie-mortgage-calculator-inputs">
        <div className="input-group">
          <label htmlFor="purchasePrice" className="input-label">Purchase price</label>
          <Input
            id="purchasePrice"
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            placeholder="e.g. 500000"
            suffix={locale === 'en-ca' ? 'CAD' : 'USD'}
          />
        </div>

        <div className="input-group">
          <label htmlFor="downPayment" className="input-label">Down payment</label>
          <Input
            id="downPayment"
            type="number"
            value={downPayment}
            onChange={(e) => setDownPayment(e.target.value)}
            placeholder="Down payment"
            suffix={
              <button 
                onClick={toggleDownPaymentType}
                className="unit-toggle"
                type="button"
              >
                {isPercentage ? '%' : '$'}
              </button>
            }
          />
        </div>

        <div className="input-group">
          <label htmlFor="interestRate" className="input-label">Interest rate</label>
          <Input
            id="interestRate"
            type="number"
            value={interestRate}
            onChange={(e) => setInterestRate(e.target.value)}
            placeholder="Interest rate"
            step="0.01"
            suffix="%"
          />
        </div>

        <div className="input-group">
          <label htmlFor="amortization" className="input-label">Amortization</label>
          <Input
            id="amortization"
            type="number"
            value={amortization}
            onChange={(e) => setAmortization(e.target.value)}
            placeholder="Amortization period"
            min="1"
            max="30"
            suffix="years"
          />
        </div>

        <div className="input-group">
          <label htmlFor="paymentFrequency" className="input-label">Payment frequency</label>
          <Select
            id="paymentFrequency"
            value={paymentFrequency}
            onChange={(value) => setPaymentFrequency(value)}
            options={[
              { value: '12', label: 'Monthly' },
              { value: '26', label: 'Bi-weekly' },
              { value: '52', label: 'Weekly' },
            ]}
          />
        </div>
      </div>

      {payment && (
        <div className="amelie-mortgage-calculator-result">
          <div className="result-text">
            <span className="payment-amount">{formatCurrency(payment)}</span>
            <span className="payment-period">{getPaymentLabel()}</span>
          </div>
          <div className="chart-container">
            <canvas ref={chartRef}></canvas>
          </div>
        </div>
      )}

      <p className="amelie-mortgage-calculator-disclaimer">
        * This calculator is for illustrative purposes only. Actual payment amounts may vary and will depend on various factors including credit history, additional fees, and other variables not considered here. Please consult with a qualified mortgage professional for accurate quotes.
      </p>
    </div>
  );
};

export default AmelieMortgageCalculator;